/* Theme css*/
html {
  background: white;
}

h1 {
  color: #d0a966;
}

p {
  color: black;
}

.theme-bg {
  background-color: white;
}

.theme-color-bg {
  background-color: #d0a966 !important;
}

.theme-color {
  color: #d0a966 !important;
}

.border-theme {
  border-color: #d0a966 !important;
}

.hover-text-theme:hover {
  color: #d0a966 !important;
}


/* Navbar css */
.navbar {
  overflow: hidden;
  border-bottom: #d0a966 2px solid;
  margin-bottom: 10px;
}

.navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
}

.navbar .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  font-weight: bold;
  text-decoration: none;
  display: block;
  text-align: left;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  font-weight: bold;
  color: #d0a966;
}

.dropdown-content a:hover {
  font-weight: bold;
  background-color: #d0a966;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 1430px) {
  .navbar a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }
  .navbar a.icon {
    float: right;
    display: block;
  }

  a.icon {
    position: absolute;
    right: 0px;
  }
}

@media screen and (max-width: 1430px) {
  .navbar.responsive {
    position: relative;
  }
  .navbar.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .navbar.responsive .dropdown {
    float: none;
  }
  .navbar.responsive .dropdown-content {
    position: relative;
  }
  .navbar.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
/* End of navbar css */

/* Stupid alignment */
.webkit-center {
  text-align: -webkit-center;
}

.webkit-right {
  text-align: -webkit-right;
}
/* End of Stupid alignment */

/* Footer css */
.footer {
  overflow: hidden;
  background-color: #000;
  border-top: #d0a966 2px solid;
  margin-top: 20px;
  text-align-last: right;
}
/* End of Footer css */

/* Additional css */
.right-37 {
  right: 37%;
}

.m-auto {
  margin: auto !important;
}

.ml-none {
  margin-left: 0px !important;
}

.min-w-50 {
  min-width: 50%;
}

.min-w-60 {
  min-width: 60%;
}

.min-w-70 {
  min-width: 70%;
}

.facebook {
  height: 45px;
  width: 45px;
  color: #ffffff;
}

.facebook:hover {
  height: 45px;
  width: 45px;
  color: #4267B2;
}

.design-catalog-modal {
  width: 33.33%;
}

@media screen and (max-width: 600px) {
  .design-catalog-modal {
    width: 75% !important;
  }
}

.design-catalog-modal-text {
  width: 33.33%;
}

@media screen and (max-width: 600px) {
  .design-catalog-modal-text {
    width: 75% !important;
  }
}